<template>
  <div class="mapbox-gl-control mouse-coords-control" :style="[dynamicOffsetStyle, posStyle]">
    <v-card outlined class="pa-2">
        <small>lat: {{mouseCoords.lat.toFixed(6)}}</small>
        <br />
        <small>long: {{mouseCoords.lng.toFixed(6)}}</small>
        <br />
        <small>EPSG:4326</small>
    </v-card>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import { mapState } from 'vuex'

export default {
  name: 'pibot-mapbox-mouse-coords',
  mixins: [positionStyleMixin, offsetStyleMixin, mapboxBasicsMixin],
  computed: {
    ...mapState({
      chartActive: state => state.mapbox.chartActive,
      chartHeight: state => state.mapbox.chartHeight,
      mouseCoords: state => state.mapbox.mouseCoords
    }),
    dynamicOffsetStyle () {
      if (!this.chartActive) return this.offsetStyle

      let offsets = JSON.parse(JSON.stringify(this.offsets))
      offsets[1] = `calc(${offsets[1]} - ${this.chartHeight})`
      offsets = offsets.join(',')
      return { transform: `translate(${offsets})` }
    }
  }
}
</script>
